import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { device } from "../device"
import Credit from "../components/credit"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const [isProgress, setProgress] = useState(true)
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0)
  const features = data.allContentfulPaintings.edges
  const currentFeature = features[currentFeatureIndex]
  const autoPlayRef = useRef()

  useEffect(() => {
    const play = () => autoPlayRef.current()
    const interval = setInterval(play, 5000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    autoPlayRef.current = nextFeature
  })

  const nextFeature = () => {
    console.log(currentFeatureIndex)
    console.log(currentFeature.node.title)
    if (features.length - 1 === currentFeatureIndex) {
      return setCurrentFeatureIndex(0)
    }

    setCurrentFeatureIndex(currentFeatureIndex + 1)
  }

  return (
    <Layout page="Home">
      <SEO title="Home" />
      <FullSlider>
        <FullSliderContent>
          {features.map((feature, index) => (
            <>
              <Slide
                key={index}
                activeIndex={currentFeatureIndex}
                bgSrc={feature.node.image.resize.src}
                index={index}
              />
              <ProgressBar
                key={index}
                activeIndex={currentFeatureIndex}
                index={index}
              />
              <CreditWrapper
                index={index}
                key={index}
                activeIndex={currentFeatureIndex}
              >
                <Credit
                  title={feature.node.title}
                  width={feature.node.width}
                  height={feature.node.height}
                  material={feature.node.material}
                />
              </CreditWrapper>
            </>
          ))}
        </FullSliderContent>
      </FullSlider>
      <IndexCopyright>
        &copy;{new Date().getFullYear()} Miki Mochizuka
      </IndexCopyright>
    </Layout>
  )
}

const FullSlider = styled.div`
  width: 100%;
  height: 100vh;
`

const FullSliderContent = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  width: 100%;
`

const CreditWrapper = styled.div`
  position: absolute;
  bottom: 38px;
  right: 40px;
  transition: opacity 1s ease-in-out;
  opacity: ${props => (props.index === props.activeIndex ? 1 : 0)};
  z-index: 8000;
`

const Slide = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-image: url("${props => props.bgSrc}");
  background-position: center;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  opacity: ${props => (props.index === props.activeIndex ? 1 : 0)};
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => (props.index === props.activeIndex ? 100 : 0)}%;
  height: 4px;
  background-color: white;
  transition: width 5s linear;
  opacity: ${props => (props.index === props.activeIndex ? 1 : 0)};
`

const IndexCopyright = styled.p`
  display: none;

  @media ${device.mobile} {
    display: block;
    position: fixed;
    bottom: 4px;
    left: 12px;
    font-size: 0.85rem;
    color: white;
    font-family: ${props => props.theme.fontFamily.roboto};
  }
`

export const query = graphql`
  query {
    allContentfulPaintings {
      edges {
        node {
          title
          material
          width
          height
          image {
            resize(width: 1800, quality: 80) {
              src
            }
          }
        }
      }
    }
  }
`

export default IndexPage
