import React from "react"
import styled from "@emotion/styled"
import { device } from "../device"

const Credit = ({ title, width, height, material, type }) => {
  return (
    <StyledCredit>
      <p>
        {title}
        {type === "image" && (
          <>
            <span>
              {width}cm x {height}cm
            </span>
            <span>{material}</span>
          </>
        )}
      </p>
    </StyledCredit>
  )
}

const StyledCredit = styled.div`
  /* background-color: red; */
  p {
    font-family: ${props => props.theme.fontFamily.lora};
    font-weight: 600;
    line-height: 1;
  }

  span {
    font-family: ${props => props.theme.fontFamily.roboto};
    font-style: italic;
    margin-left: 8px;
  }

  @media ${device.mobile} {
    p {
      font-size: 12px;
    }
  }
`
export default Credit
